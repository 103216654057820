/* created by @ericariyanto since (c) 2019 */
@font-face {
  font-family: 'Avenir-Black';
  src: url('../fonts/Avenir-Black.ttf') format('ttf');
}
@font-face {
  font-family: 'Avenir-Light';
  src: url('../fonts/Avenir-Light.ttf') format('ttf');
}
@font-face {
  font-family: 'Avenir-Heavy';
  src: url('../fonts/Avenir-Heavy.ttf') format('ttf');
}

@font-face {
    font-family: 'Inter UI Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Inter UI Medium'), url('../fonts/Inter-UI-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Exo-Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Exo-Regular'), url('../fonts/Exo-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Exo-Medium';
  font-style: normal;
  font-weight: normal;
  src: local('Exo-Medium'), url('../fonts/Exo-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'Exo-Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Exo-Bold'), url('../fonts/Exo-Bold.ttf') format('truetype');
}

:root {
    --bg-main: radial-gradient(36% 40%, #5666D1 -97%, #394176 48%, #2B2E5D 131%);
    --bg-sidebar: #1f2344;
    --font-family: 'Exo-Regular';
    --font-color: #FFFFFF;
    --font-size-largest : 18px;
    --font-size-large : 14px;
    --font-size-normal : 12px;
    --font-size-small : 10px;
    --font-size-smallest : 8px;
    --font-size-smallest-low : 7px;
    --font-size-smallest-super : 6px;
    --font-size-smallest-super-ultra : 5px;
    --color-blue: #00c0ef;
    --color-red: #dd4b39;
    --color-green: #00a65a;
    --color-orange: #f39c12;

    --header-height: 80px;    
    
    --color-bg : #12380c;
    --color-emphaze : #83b54d;
    --resume-item-height : 55px;
    --resume-item-width : 166px;
    --resume-item-content-width : 92px;
}

div::-webkit-scrollbar-thumb {
    background-color: #12380c;
}

div::-webkit-scrollbar-track {    
    background-color: #2c6e2f;
}

.bg-color-blue {
    background: var(--color-blue) !important;
}
.bg-color-red {
    background: var(--color-red) !important;
}
.bg-color-green {
    background: var(--color-green) !important;
}
.bg-color-orange {
    background: var(--color-orange) !important;
}

body {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: var(--font-size-normal);
    line-height: 1.5;
    color: var(--font-color);
    background-color: var(--color-bg);
    /*width: 100%;
    height: 100vh;
    overflow: hidden;*/
}

.skin-blue .left-side, 
.skin-blue .main-sidebar, 
.skin-blue .wrapper,
.wrapper {
    background: transparent;
}

.main-header {
    display: inline-block;    
    width: 100%;
    background-color: #092609;    
    min-height: var(--header-height);
    max-height: var(--header-height);    
    margin-bottom: -5px;
}
    
    .main-header .logo-app {
        float: left;
        display: inline-block;
        width: 65%;
        max-height: var(--header-height);
        overflow: hidden;
        text-align: left;
        padding: 0px 15px;
        padding-left: 0px;
    }
        .main-header .logo-app .logo-main, 
        .main-header .logo-app .logo-text {
            float: left;
            display: inline-block;            
        }

        .main-header .logo-app .logo-main {
            background-color: transparent;
            padding: 18px 15px;       
        }

        .main-header .logo-app .logo-text {            
            padding: 27px 15px;
        }

        .main-header .logo-app .logo-main img {
            height: 35px;
            width: auto;
            max-width: 100%;
        }

        .main-header .logo-app .logo-text img {
            height: 25px;
            width: auto;
            max-width: 100%;   
        }
    
    .main-header .main-menu-container {
        float: left;
        display: inline-block;
        width: 60%;
        max-height: var(--header-height);
        overflow: hidden;
        text-align: center;        
        padding: 23px 5px;       
    }
        .main-header .main-menu-container .main-search-container {
            display: inline-block;
            width: 100%;
        }

        .main-header .main-menu-container .main-search-container .input-search {
            position: relative;
            width: 80%;
            max-width: 450px;
            margin: 0px auto;
            -webkit-transition: width 1.8s ease-in-out;
            transition: width 1.8s ease-in-out;
            font-size: var(--font-size-small);
        }

        .main-header .main-menu-container .main-search-container .input-search span {
            position: absolute;
            top: 10px;
            left: 20px;
            color: var(--color-emphaze);
        }

        .main-header .main-menu-container .main-search-container .form-control {
            background-color: #30334D;
            border: none;
            border-radius: 100px;
            padding-left: 40px;
            color: rgba(255,255,255,0.47);
            letter-spacing: 0.76px;            
        }

        .main-header .main-menu-container .menu-header-item {
            display: inline-block;
            margin-top: 0px;
        }
            .main-header .main-menu-container .menu-header-item img {
                height: 110px;
                position: relative;
                top: 5px;
                padding: 10px 0px;
            }

    .main-header .timepanel {
        float: right;
        display: inline-block;
        width: 25%;
        max-width: 190px;
        max-height: var(--header-height);
        overflow: hidden;
        text-align: center;
        padding: 17px 5px;
    }
    
    .main-header .timepanel .timepanel-datetime {
        display: inline-block;
        width: 100%;
        width: calc(100% - 60px);
        border-right: 1px solid #30334D;
        min-height: 20px;
        text-align: right;
        padding: 0px 0px;
        padding-top: 5px;
        padding-right: 10px;
        color: #FFFFFF;
    }
        .main-header .timepanel .timepanel-datetime .timepanel-datetime-date {
            font-family: 'Avenir-Light',Roboto,sans-serif;
            font-size: var(--font-size-smallest);
            letter-spacing: 0.76px;
        }
        .main-header .timepanel .timepanel-datetime .timepanel-datetime-time {
            font-family: 'Avenir-Heavy',Roboto,sans-serif;
            font-size: var(--font-size-large);
            font-weight: bold;
            letter-spacing: 0.76px;
        }
    .main-header .timepanel .timepanel-weather {
        display: inline-block;
        width: 50px;
    }
        .main-header .timepanel .timepanel-weather img {
            height: 48px;
            margin-top: -20px;
            position: relative;
        }

.main-menu {
    background-color: #215626;
    color: #fff;
    padding: 0px 5%;
    text-align: center;
    margin-bottom: 25px;
}
    .main-menu ul {            
        display: inline-block;
        width: 100%;
        margin-bottom: 0px;
        padding-left: 0px;
    }
    .main-menu ul li {
        display: inline-block;
    }

    .main-menu ul li a {
        display: inline-block;
        padding: 10px 10px;
        cursor: pointer;
        border-bottom: none;        
        color: #FFFFFF;        
        letter-spacing: 0;
        text-align: left;
        line-height: 21px;
        font-size: var(--font-size-large);
    }
    .main-menu ul li:first-child a {
        margin-left: -10px;        
    }

    .main-menu ul li a:focus, 
    .main-menu ul li a:hover, 
    .main-menu ul li.active a {
        transition-timing-function: ease-in-out;
        color: #84B64E;
        /*border-bottom: 2px solid #84B64E;*/
    }

.content-wrapper {
    background: var(--bg-main);    
}
    .content-header {
        padding: 30px 40px;
        padding-bottom: 10px;
    }
        .content-header>h1 {
            font-family: Exo-Medium;
            font-size: var(--font-size-normal);
            color: #FFFFFF;
            letter-spacing: 0;
        }
        .content-header>h1>small {
            padding-left: 20px;
            position: relative;
            bottom: -4px;
        }
            #app-date {
                font-family: Exo-Regular;
                font-size: var(--font-size-smallest);
                color: #ffffff;
                letter-spacing: .73px;
                text-align: right;
            }
            #app-time {
                font-family: Exo-Medium;
                font-size: var(--font-size-large);
                color: #fff;
                letter-spacing: 1.30px;
                text-align: right;
            }

    .content {
        padding-left: 40px;
        padding-right: 40px;
    }

.main-sidebar {
    background: var(--bg-sidebar) !important;       
    box-shadow: 0 2px 8px 0 rgba(0,0,0,0.23);
    padding-top: 0px;
}

    .main-sidebar .div-logo-sidebar {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100px;
        background-color: #272e5c;        
        overflow: hidden;
        margin-bottom: -1px;
    }
        .main-sidebar .div-logo-sidebar img {
            width: 70%;
            height: auto;
        }
        .skin-blue .sidebar-form {
            border-radius: none;
            border: none;
            margin: 0px 0px;
        }            
            .skin-blue .sidebar-form .btn, .skin-blue .sidebar-form input[type=text] {
                color: #d5d5d5;
                background-color: #1f2344;
                font-size: var(--font-size-small);
            }
            .skin-blue .sidebar-form input[type=text]:focus, .skin-blue .sidebar-form input[type=text]:focus+.input-group-btn .btn {
                background-color: #1c1f3c;
                color: #fff;
            }
        .skin-blue .sidebar-menu>li>a {
            border-left: none;            
            color: #fff !important;
            font-family: Exo-Regular;
            font-size: var(--font-size-normal);
            text-transform: uppercase;
            color: #FFFFFF;
            letter-spacing: 1px;
            text-align: center;
            border-bottom: 1px solid #373E71;
        }
        .skin-blue .sidebar-menu>li.active>a,
        .skin-blue .sidebar-menu>li.active>a, 
        .skin-blue .sidebar-menu>li.menu-open>a, 
        .skin-blue .sidebar-menu>li:hover>a {
            background: #373e71 !important;
        }

.ps-content-block {
    background: #2b6e2f;
    color: #FFFFFF;
    padding: 10px 0px;
    padding-bottom: 0px;
    border-radius: 10px;
    margin-bottom: 15px;
}
    .ps-content-block.darker {
        background: #215627;
    }
    .ps-content-block.large-widgets {        
        box-shadow: none;
        border: 1px solid #215626;
        font-size: var(--font-size-large);
    }
        .ps-content-block.large-widgets .ps-content-block-body {
            padding: 0px 0px;
        }
        .ps-content-block.large-widgets .ps-content-block-body .large-widget {
            display: inline-block;
            width: 100%;
        }
            .ps-content-block.large-widgets .ps-content-block-body .large-widget .large-widget-title {
                padding: 10px 0px;
                display: inline-block;
                width: 100%;
            }
                .large-widget-title-label,
                .large-widget-title-value {
                    width: 49%;
                    display: inline-block;
                    float: left;
                    text-align: center;
                    padding: 0 15px;
                    font-size: var(--font-size-smallest);
                }
                .large-widget-title-label {
                    padding-top: 4px;
                    line-height: 1.3;
                    position: relative;
                    top: 0px;
                    right: -15%;
                }
                .large-widget-title-value {
                    float: right;
                    text-align: left;
                    font-size: var(--font-size-largest);
                    color: #f8e71d;
                    font-family: Exo-Bold;
                }
            .ps-content-block.large-widgets .ps-content-block-body .large-widget .large-widget-content {
                display: inline-block;
                width: 100%;
                border-top: 1px solid #215626;
                padding: 0px 10px;
            }
                .large-widget-content-left, 
                .large-widget-content-center, 
                .large-widget-content-right {
                    display: inline-block;
                    width: 31%;
                    text-align: center;
                    padding: 10px 0px;
                }
                .large-widget-content-center {
                    width: 35%;
                    border-left: 1px solid #215626;
                    border-right: 1px solid #215626;
                }
                    .ps-content-block.large-widgets .ps-content-block-body .large-widget .large-widget-content .large-widget-content-item {
                        font-size: 25px;
                    }
                        .ps-content-block.large-widgets .ps-content-block-body .large-widget .large-widget-content .large-widget-content-item img {
                            height: 30px;
                            width: auto;
                            margin: 5px 5px;
                            margin-top: 0px;
                            margin-left: 0px;
                        }
    .widget-resume-large {
        display: inline-block;
        width: 100%;
    }
        .widget-resume-large .resume-on-top,
        .widget-resume-large .resume-on-bottom {
            display: inline-block;
            width: 100%;            
            height: 218px;
            padding: 15px 20px;
            overflow: hidden;            
        }
        .widget-resume-large .resume-on-top {
            background: #014C1A;
            height: 228px;
        }
            .widget-resume-large .resume-on-top .resume-large-item {                
                text-align: center;
                padding: 20px 10px;
                margin-top: 30px;
            }
                .widget-resume-large .resume-on-top .resume-large-item .item-icon,
                .widget-resume-large .resume-on-top .resume-large-item .item-value {
                    display: inline-block;
                }
                .widget-resume-large .resume-on-top .resume-large-item .item-icon {                    
                    margin-right: 20px;
                }
                    .widget-resume-large .resume-on-top .resume-large-item .item-icon img {
                        margin-bottom: 15px;
                        height: 90px;
                        width: auto;
                    }
                .widget-resume-large .resume-on-top .resume-large-item .item-value {
                    height: 110px;
                    font-size: 60px;
                    letter-spacing: 2.09px;
                    font-weight: 500;
                    line-height: 1.2;
                }
                    .widget-resume-large .resume-on-top .resume-large-item .item-value a {
                        color: #fff;
                        height: 100%;
                        overflow: hidden;
                        padding: 0 10px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                    }
                        .widget-resume-large .resume-on-top .resume-large-item .item-value a div {
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 2;
                            word-wrap: break-word;
                            overflow: hidden;
                            margin: 0 0;
                        }
                            .widget-resume-large .resume-on-top .resume-large-item .item-value a .item-value-label {
                                font-size: 30%;
                                letter-spacing: 0.79px;
                            }
        .widget-resume-large .resume-on-bottom {
            padding: 35px 30px;
            padding-top: 45px;
            display: flex;
            flex-direction: row;
            margin: 0;
        }
            .widget-resume-large .resume-on-bottom .resume-small-item {
                list-style: none;
                flex-grow: 1;
                flex-shrink: 1;
                flex-basis: 0;
                text-align: center;
                padding: 20px 10px;
            }
                .widget-resume-large .resume-on-bottom .resume-small-item .item-label {
                    margin-bottom: 10px;
                }
                .widget-resume-large .resume-on-bottom .resume-small-item .item-value {
                    height: calc(100% - 35px);
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    font-size: 50px;
                    font-weight: 500;
                }
                    .widget-resume-large .resume-on-bottom .resume-small-item .item-value a {
                        color: #fff;
                        height: 100%;
                        /*display: flex;
                        flex-direction: column;
                        justify-content: center;*/
                    }
                        .widget-resume-large .resume-on-bottom .resume-small-item .item-value a i.fa {
                            font-size: 30px;
                            position: relative;
                            top: -8px;
                        }

    .slick-list {
        margin-bottom: 20px;
    }
        .slick-list .slick-item {
            display: inline-block;
            width: 440px;
            background-color: #00591E;
            margin: 5px 10px;
        }
            .slick-list .slick-item .widget-resume-large .resume-title {
                display: inline-block;
                width: 100%;
                padding: 10px 10px;                
            }
                .slick-list .slick-item .widget-resume-large .resume-title h4 {
                    text-align: center;
                    text-transform: uppercase;
                    letter-spacing: 1.59px;
                }
            .slick-list .slick-item .widget-resume-large .resume-on-bottom, 
            .slick-list .slick-item .widget-resume-large .resume-on-top {
                height: 90px;
                padding-top: 20px;
            }
            .slick-list .slick-item .widget-resume-large .resume-on-top .resume-large-item {
                padding: 0px 0px;
                margin-top: 0px;
            }
            .slick-list .slick-item .widget-resume-large .resume-on-top .resume-large-item .item-value {
                font-size: 26px;
                height: 50px;
            }
            .slick-list .slick-item .widget-resume-large .resume-on-bottom {
                padding: 0px 0px;
            }
            .slick-list .slick-item .widget-resume-large .resume-on-bottom .resume-small-item {
                padding: 0px 0px;
            }
            .slick-list .slick-item .widget-resume-large .resume-on-bottom .resume-small-item .item-value {
                font-size: 20px;
            }
            .slick-list .slick-item .widget-resume-large .resume-on-bottom .resume-small-item .item-value a i.fa {
                font-size: 14px;
                top: -3px;
            }
            .slick-list .slick-item .widget-resume-large .resume-on-bottom .resume-small-item .item-label {
                height: 35px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                font-size: 10px;
            }
            .slick-list .slick-item .widget-resume-large .resume-on-top .resume-large-item .item-value a .item-value-label {
                font-size: 11px;
            }

    .ps-content-block.with-shadow {
        box-shadow: 0 0px 0px 0 rgba(0,0,0,0.50);
    }
    .ps-content-block.with-header {
        padding-top: 0px;
    }
    .ps-content-block .ps-content-block-header {
        display: inline-block;
        width: 100%;
        background: #215626;
        padding: 15px 10px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }
        .ps-content-block .ps-content-block-header h3 {
            font-size: var(--font-size-largest);
            margin: 0px 0px;
            padding: 0px 0px;
            text-align: center;
        }
    .ps-content-block .ps-content-block-body {
        padding: 5px 10px;
    }
    .ps-content-block.with-header .ps-content-block-body {
        padding-top: 15px;
        background: #00591e;
    }
        .ps-content-block .ps-content-block-body h3.block-title {
            margin: 0px 0px;
            padding: 5px 10px;
            font-size: var(--font-size-small);
        }
        .ps-content-block .ps-content-block-body .ps-content-items {
            height: 360px;
            padding: 5px 5px;
            padding-top: 0px;
            overflow: auto;
            margin-bottom: 10px;
        }        
            .ps-content-block .ps-content-block-body .ps-content-items .ps-content-item {
                display: inline-block;
                width: 98%;
                margin: 1% 1%;
                margin-top: 0px;
                margin-bottom: 2%;
                overflow: hidden;
                font-family: Exo-Regular;
                font-size: var(--font-size-large);
                color: #fff;
                border: 1px solid #215626;                
                background: #00591e;
                padding: 0;
                border-radius: 5px;
            }            
                .ps-content-block .ps-content-block-body .ps-content-items .ps-content-item .ps-content-item-cover {
                    display: inline-block;
                    height: 220px;
                    overflow: hidden;
                }
                    .ps-content-block .ps-content-block-body .ps-content-items .ps-content-item .ps-content-item-cover img {
                        max-width: 100%;
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                    }
                .ps-content-block .ps-content-block-body .ps-content-items .ps-content-item .ps-content-author {
                    display: inline-block;
                    width: 100%;
                    margin-bottom: 5px;
                }
                    .ps-content-block .ps-content-block-body .ps-content-items .ps-content-item .ps-content-author img {
                        width: 25px;
                        height: 25px;
                        float: left;
                    }
                    .ps-content-block .ps-content-block-body .ps-content-items .ps-content-item .ps-content-author .author-content {
                        margin-left: 10px;
                        float: left;
                        padding-top: 2px;
                    }
                .ps-content-block .ps-content-block-body .ps-content-items .ps-content-item .ps-content-item-body {
                    padding: 10px 10px;
                    background-color: #014c1a;
                }
                    .ps-content-block .ps-content-block-body .ps-content-items .ps-content-item .ps-content-item-body .ps-content-item-footer {
                        display: inline-block;
                    }
                        .ps-content-block .ps-content-block-body .ps-content-items .ps-content-item .ps-content-item-body .ps-content-item-footer img {
                            display: inline-block;
                            float: left;
                            width: 25%
                        }
                    .ps-content-block .ps-content-block-body .ps-content-items .ps-content-item .ps-content-item-body .ps-content-item-content {
                        overflow: hidden;
                    }
                    .ps-content-block .ps-content-block-body .ps-content-items .ps-content-item .ps-content-item-body .ps-content-item-footer-text {
                        display: inline-block;
                        float: left;
                        font-size: var(--font-size-smallest);
                        width: 70%;
                        margin-left: 5%;
                        padding-top: 1px;
                    }
                        .ps-content-block .ps-content-block-body .ps-content-items .ps-content-item .ps-content-item-body .ps-content-item-footer-text .text-name {
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                        .ps-content-block .ps-content-block-body .ps-content-items .ps-content-item .ps-content-item-body .ps-content-item-footer-text .text-time {
                            font-size: var(--font-size-smallest-low);
                        }
            .ps-content-block .ps-content-block-body .ps-content-items .ps-content-item.ps-content-item-full {
                width: 100%;
                margin: 0px 0px;
                background: transparent;
                border: none;
            }
                .ps-content-block .ps-content-block-body .ps-content-items .ps-content-item.ps-content-item-full .ps-content-item-cover {
                    height: 240px;
                    width: 100%;
                    border-bottom-left-radius: 5px;
                    border-bottom-right-radius: 5px;
                }            
    .ps-content-block .ps-content-block-footer {
        display: inline-block;
        width: 100%;
        background: #215626;
        padding: 13px 10px;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }
        .ps-content-block .ps-content-block-footer h3 {
            font-size: var(--font-size-large);
            margin: 0px 0px;
            padding: 0px 0px;
            text-align: center;
        }
.ps-counts {
    display: inline-block;
    width: 100%;
}
    .ps-counts .ps-count-item {
        display: inline-block;        
        padding: 5px 0px;
        width: 100%;
    }
    .ps-counts.ps-counts-third .ps-count-item {
        width: 31.5%;
        margin: 0 1%;
    }
        .ps-counts.ps-counts-third .ps-count-item.ps-count-item-first {
            margin-left: 0;
        }
        .ps-counts.ps-counts-third .ps-count-item.ps-count-item-last {
            margin-right: 0;
        }
        .ps-counts .ps-count-item .ps-count-item-container {
            display: inline-block;
            width: 100%;
            height: 60px;
            background: #1f2344;
            font-size: var(--font-size-smallest);
            border-radius: 5px;
        }
        .ps-counts .ps-count-item .ps-count-item-container .ps-count-item-text {
            display: inline-block;
            float: left;
            width: 65%;
            height: 100%;
            overflow: hidden;
            padding: 5px 10px;
        }
            .ps-counts .ps-count-item .ps-count-item-container .ps-count-item-text .text-container {
                display: grid;
                width: 100%;
                height: 100%;
                text-align: center;                    
            }
                .ps-counts .ps-count-item .ps-count-item-container .ps-count-item-text .text-container .text-number,
                .ps-counts .ps-count-item .ps-count-item-container .ps-count-item-text .text-container .text-label {
                    display: inline-block;
                    width: 100%;
                    margin: auto;
                }
                .ps-counts .ps-count-item .ps-count-item-container .ps-count-item-text .text-container .text-number {
                    font-size: var(--font-size-small);
                }
        .ps-counts .ps-count-item .ps-count-item-container .ps-count-item-icon {
            width: 30%;
            height: 100%;
            float: right;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }
            .ps-counts .ps-count-item.ps-count-item-blue .ps-count-item-container .ps-count-item-icon {
                background: var(--color-blue) !important;
            }
            .ps-counts .ps-count-item.ps-count-item-red .ps-count-item-container .ps-count-item-icon {
                background: var(--color-red) !important;
            }
            .ps-counts .ps-count-item.ps-count-item-green .ps-count-item-container .ps-count-item-icon {
                background: var(--color-green) !important;
            }
            .ps-counts .ps-count-item.ps-count-item-orange .ps-count-item-container .ps-count-item-icon {
                background: var(--color-orange) !important;
            }
            .ps-counts .ps-count-item .ps-count-item-container .ps-count-item-icon i {
                font-size: 24px;
            }

.mapboxgl-map {
    border-radius: 10px;
}






















#page-content-body {
    padding: 0px 1%; 
}

.content-section {
    display: inline-block;
    width: 100%;
    padding: 5px 0px;
}    
    .content-section .section-header {
        display: inline-block;
        width: 100%;
        margin-bottom: 10px;
    }

    .content-section .section-header h3 {
        float: left;
        display: inline-block;
        font-size: var(--font-size-small);
        color: var(--color-emphaze);
        text-transform: uppercase;
        letter-spacing: 0.96px;
        margin: 0px 0px;
        padding: 5px 0px;
        padding-right: 5px;
    }
    .content-section .section-header .section-header-filter {        
        display: inline-block;
        float: right;
    }
    .content-section .section-header .section-header-filter .filter-options {
        display: inline-block;
    }
    .content-section .section-header .section-header-filter .filter-options .filter-options-item {
        display: inline-block;
    }
    .content-section .section-header .section-header-filter .filter-options .filter-options-item label {
        margin: 0px 0px;
        margin-right: 5px;
        font-size: var(--font-size-smallest-low);
        color: #fff;
    }
    .content-section .section-header .section-header-filter .filter-options .filter-options-item .select2-container {
        display: inline-block;
        min-width: 100px;
        background-color: var(--color-bg);
    }
    .content-section .section-header .section-header-filter .filter-options .filter-options-item .select2-container .select2-selection--single {
        background-color: var(--color-bg);
        border: 1px solid #30334d;
        height: 23px;    
        padding: 3px 24px 3px 12px;
    }
    .content-section .section-header .section-header-filter .filter-options .filter-options-item .select2-container .select2-results__options,
    .content-section .section-header .section-header-filter .filter-options .filter-options-item .select2-container .select2-selection__rendered {
        font-size: var(--font-size-smallest-low) !important;
        color: #fff;
        margin-top: 3px !important;
    }
    .select2-container--bootstrap .select2-results__option,
    .select2-container--bootstrap .select2-results__option[aria-selected=true] {    
        color: #fff;    
        background-color: #1d1e30;
        font-size: var(--font-size-smallest-low);
    }    
    .content-section .section-header .section-header-filter .btn {
        padding: 5px 10px;
        font-size: var(--font-size-smallest-low);
    }

.graph-item {
    overflow: hidden;    
    background-color: #292c42;
    padding: 10px 15px;
    box-shadow: 2px 1px 8px 0 #0000004a;
    font-size: var(--font-size-smallest-low);
}
    .graph-item .graph-item-title {
        display: inline-block;
        width: 100%;
        height: 15px;
        overflow: hidden;
    }

    .graph-item .graph-item-title h5 {
        display: inline-block;
        float: left;
        margin: 0px 0px;
        padding: 2px 2px;   
        font-size: var(--font-size-small);     
    }

    .graph-item .graph-item-title .graph-item-tools {
        display: inline-block;
        float: right;
    }

    .graph-item .graph-item-content {
        height: 30px;
        overflow: hidden;
    }

.sosmed-list {
    display: inline-block;
    float: left;
    width: 100%;
    margin: 0px 0px;
    padding: 0px 0px;    
    max-height: 295px;
    overflow-y: auto;
}

    .sosmed-list .sosmed-item {
        display: inline-block;
        width: 49%;
        margin: 5px 0px;
        margin-left: 0;
        font-size: var(--font-size-smallest-low);
        color: #fff;
    }

    .sosmed-list .sosmed-item.set-margin-to-left {
        margin: 5px 8px;
        margin-right: 0px;
    }
        .sosmed-list .sosmed-item .sosmed-item-cover {
            width: 100%;
            height: 70px;            
            justify-content: center;
            display: flex;
            flex-direction: row;
            overflow: hidden;
            border-top-right-radius: 5px;
            border-top-left-radius: 5px;            
        }
            .sosmed-list .sosmed-item .sosmed-item-cover img {
                flex: 1;
                height: 100%;
                object-fit: cover;
            }        

        .sosmed-list .sosmed-item .sosmed-item-contents {
            display: inline-block;
            float: left;
            width: 100%;
            background-color: #215626;
            padding: 10px 10px;
            border-bottom-right-radius: 5px;
            border-bottom-left-radius: 5px;
        }
            .sosmed-list .sosmed-item .sosmed-item-contents.no-cover-image {
                border-top-right-radius: 5px;
                border-top-left-radius: 5px;       
            }
            .sosmed-list .sosmed-item .sosmed-item-contents {
                display: inline-block;;
                float: left;
                width: 100%;
                min-height: 50px;
            }

            .sosmed-list .sosmed-item .sosmed-item-contents .sosmed-item-footer {
                display: inline-block;
                float: left;
                width: 100%;
                margin-bottom: 5px;
            }
                .sosmed-list .sosmed-item .sosmed-item-contents .sosmed-item-footer img {
                    display: inline-block;
                    float: left;
                    width: 20px;
                    height: 20px;                    
                }

                .sosmed-list .sosmed-item .sosmed-item-contents .sosmed-item-footer .sosmed-item-footer-author {
                    display: inline-block;
                    float: right;
                    width: 60px;
                }

.fancybox-slide--html .fancybox-close-small {
    right: 30px;
    top: 15px;
}

.sosmed-list-full.sosmed-list {
    max-height: 90vh;
}
.sosmed-list-full.sosmed-list .sosmed-item {
    width: 100%;
    max-width: 650px;    
    font-size: var(--font-size-large);    
}
    .sosmed-list-full.sosmed-list .sosmed-item .sosmed-item-cover {
        height: 370px;
    }
    .sosmed-list-full.sosmed-list .sosmed-item .sosmed-item-contents {
        padding: 20px 25px;
    }
        .sosmed-list-full.sosmed-list .sosmed-item .sosmed-item-contents .sosmed-item-author {
            display: inline-block;
            width: 100%;
            margin-bottom: 5px;
        }
            .sosmed-list-full.sosmed-list .sosmed-item .sosmed-item-contents .sosmed-item-author img {
                width: 25px;
                height: 25px;
                float: left;
            }
            .sosmed-list-full.sosmed-list .sosmed-item .sosmed-item-contents .sosmed-item-author .author-content {
                margin-left: 10px;
                float: left;
                padding-top: 2px;
            }
        .sosmed-list-full.sosmed-list .sosmed-item .sosmed-item-contents .sosmed-item-content {
            margin-bottom: 30px;
        }
        .sosmed-list-full.sosmed-list .sosmed-item .sosmed-item-contents .sosmed-item-footer {
            font-size: var(--font-size-small);    
        }
            .sosmed-list-full.sosmed-list .sosmed-item .sosmed-item-contents .sosmed-item-footer img {
                width: 50px;
                height: 50px;
            }
            .sosmed-list-full.sosmed-list .sosmed-item .sosmed-item-contents .sosmed-item-footer .sosmed-item-footer-author {
                width: 75%;
                padding-top: 5px;
                float: left;
                margin-left: 20px;
            }

.popup-container {
    width: 100%;
    max-width: 1024px;
    background-color: #265d28;
    padding: 30px 30px;
    margin-right: 10px;
}

.infograph {
    display: inline-block;
    width: 100%;    
    min-height: 100px;
    max-height: 600px;
    overflow-y: auto;
    font-size: var(--font-size-large);  
}
    .infograph .infograph-cover {
        display: inline-block;
        width: 100%;
    }
        .infograph .infograph-cover img {
            width: 100%;
            height: auto;
        }
    .infograph .infograph-content {
        display: inline-block;
        width: 100%;
        padding: 10px 0px;
    }
        .infograph .infograph-content h3 {
            display: inline-block;
            width: 100%;
            margin: 0px;
            margin-bottom: 10px;
            font-size: var(--font-size-large);
        }
        .infograph .infograph-content .infograph-desc {
            font-size: var(--font-size-normal); 
            display: inline-block;
            width: 100%;   
        }
.popup-container .infograph.infograph-main {
    text-align: center;
}
    .popup-container .infograph.infograph-main .infograph-cover {
        width: 75%;
    }
    .popup-container .infograph-list .infograph {
        border-bottom: 1px solid #265d28;
    }

.infograph-list {
    display: inline-block;
    width: 100%;
    max-height: 600px;
    overflow-y: auto;
}
    .infograph-list h4 {
        margin: 0px 0px;
        margin-bottom: 10px;
        padding: 0px 0px;
        color: var(--color-emphaze);
        letter-spacing: 2.76px;
        font-size: var(--font-size-large);
        text-transform: uppercase;
    }
.infograph-list .infograph {
    font-size: var(--font-size-smallest);
    margin-bottom: 10px;
    border-bottom: 1px solid #4e506f;    
}
    .infograph-list .infograph .infograph-cover {
        display: inline-block;
        width: 100%;
        height: 210px;
        overflow: hidden;
    }
        .infograph-list .infograph .infograph-cover img {
            width: 100%;
            height: auto;
            object-fit: fill;
        }
    .infograph-list .infograph .infograph-content {        
        padding: 0px 0px;
    }

.ps-posts {
    display: inline-block;
    width: 100%;
}
    .ps-posts .ps-post {
        display: inline-block;
        width: 145px;        
        overflow: hidden;
        font-size: var(--font-size-smallest);
        color: #adb5bd;
        background-color: #292c42;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        padding: 0px 0px;
        padding-bottom: 15px;
        cursor: pointer;
        margin: 2px 5px;
        margin-bottom: 10px;
        float: left;
    }
    .ps-posts .ps-post:hover {
        color: #adb5bd;
    }
    .ps-posts .ps-post .ps-post-cover {
        display: inline-block;
        width: 100%;
        background-repeat:no-repeat;
        background-position: center center;   
        background-size: cover;         
        height: 95px;
    }

    .ps-posts .ps-post .ps-post-body {
        display: inline-block;
        width: 100%;
        min-height: 30px;
        overflow: hidden;
        padding: 5px 15px;
        padding-top: 0px;
        margin-top: 10px;        
    }
    .ps-posts .ps-post .ps-post-footer {
        display: inline-block;
        width: 100%;
        padding: 5px 15px;
        margin-top: 15px;
    }
        .ps-posts .ps-post .ps-post-footer .ps-post-avatar {
            display: inline-block;
            width: 25px;
            float: left;            
        }
            .ps-posts .ps-post .ps-post-footer .ps-post-avatar img {
                width: 25px;
                height: 25px;
                border-radius: 50%;
            }

        .ps-posts .ps-post .ps-post-footer .ps-post-author {
            display: inline-block;            
            width: 80px;
            float: left;
            margin-left: 10px;
        }

        .ps-posts .ps-post .ps-post-footer .ps-post-author h5 {
            margin: 0px 0px;
            padding: 0px 0px;
            font-size: var(--font-size-smallest);
        }

        .ps-posts .ps-post .ps-post-footer .ps-post-author span {
            font-size: var(--font-size-smallest-low);
        }

table {
    font-size: var(--font-size-large);
    margin-bottom: 5px !important;
}
     table tbody tr,
    .table-striped>tbody>tr:nth-of-type(odd) {
        background: transparent;
    }

    .table-hover>tbody>tr:hover {
        background-color: #2c6e2f;
    }

    .table-bordered {
        border: none;
    }

    .table-bordered>tbody>tr>td,
    .table-bordered>tbody>tr>th,
    .table-bordered>tfoot>tr>td,
    .table-bordered>tfoot>tr>th,
    .table-bordered>thead>tr>td,
    .table-bordered>thead>tr>th {        
        font-size: var(--font-size-large);
        border: none;
        border-top: 1px solid rgba(255, 255, 255, 0.1);
        padding: 4px 5px;
    }
    .table-bordered>tbody>tr>th {
        border-top: none;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }

.section-content {
    margin-bottom: 10px;
}

.section-content .nav-tabs-custom {
    background-color: #292c42;
    color: #adb5bd;
}
    .section-content .nav-tabs-custom>.nav-tabs,
    .section-content .nav-tabs-custom>.tab-content,
    .section-content .nav-tabs-custom>.nav-tabs>li.active:hover>a, 
    .section-content .nav-tabs-custom>.nav-tabs>li.active>a {
        background-color: #292c42;
        border: none;
    }

    .section-content .nav-tabs-custom>.tab-content {
        padding: 10px 20px;
    }

    .section-content .nav-tabs-custom>.nav-tabs>li.header {
        color: #adb5bd;
        font-size: var(--font-size-small);
    }

    .section-content .nav-tabs-custom>.nav-tabs,
    .section-content .nav-tabs-custom>.nav-tabs>li.active:hover>a,
    .section-content .nav-tabs-custom>.nav-tabs>li.active>a,
    .section-content .nav-tabs-custom>.tab-content {
        padding: 0 10px;
        padding-bottom: 0px;
        overflow: hidden;
    }
    
    .section-content .nav-tabs-custom>.nav-tabs>li>a,
    .section-content .nav-tabs-custom>.nav-tabs>li>a:hover {
        color: #adb5bd;
        font-size: var(--font-size-smallest);
        padding: 7px 5px;
    }

    .section-content .nav-tabs-custom>.nav-tabs>li.header {
        line-height: 26px;
        padding: 0 5px;
    }

.pswidget {
    display: inline-block;
    width: 100px;    
    display: inline-block;
    float: left;
    width: 110px;
    overflow: hidden;
    min-height: var(--resume-item-height);
    padding: 5px 20px;
    background: #292C42;
    box-shadow: 0 2px 8px 0 rgba(0,0,0,0.40);
    margin: 10px 5px;
    margin-top: 0px;
}
.pswidget.pswidget-small {
    width: 60px;
}
    .pswidget .pswidget-content {
        display: inline-block;
        float: left;
        width: 70px;
        padding: 5px 0;
    }
        .pswidget .pswidget-content h4 {
            color: #84b64e;
            margin: 5px 0;
            margin-bottom: 0px;
            margin-top: 7px;
            padding: 0 0;
            font-size: var(--font-size-normal);
        }
        .pswidget .pswidget-content p {
            display: inline-block;
            width: 100%;
            font-size: var(--font-size-smallest-super);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-bottom: 0px;  
        }
    .pswidget .pswidget-icon {
        display: inline-block;
        width: 30px;
        float: right;
        -webkit-transition: all .3s linear;
        -o-transition: all .3s linear;
        transition: all .3s linear;
        color: #546ae0;
        padding: 0px 8px;
        margin: 0px 0px;
        margin-top: 14px;        
    }
        .pswidget .pswidget-icon img {
            max-width: 30px;
            max-height: 30px;
        }

.section-content .resume-item {
    display: inline-block;
    float: left;
    width: var(--resume-item-width);
    overflow: hidden;
    min-height: var(--resume-item-height);
    padding: 5px 20px;
    background: #292C42;
    box-shadow: 0 2px 8px 0 rgba(0,0,0,0.40);
    margin: 10px 5px;
    margin-top: 0px;
}
    .section-content .resume-item.resume-item-last {
        margin-right: 0px;
    }
    .section-content .resume-item .resume-item-content {
        display: inline-block;
        float: left;
        width: var(--resume-item-content-width);
        padding: 5px 0;
    }

    .section-content .resume-item .resume-item-content h4 {
        color: #84b64e;
        margin: 5px 0;
        margin-bottom: 0px;
        margin-top: 7px;
        padding: 0 0;
        font-size: var(--font-size-normal);
    }

    .section-content .resume-item .resume-item-content p {
        display: inline-block;
        width: 100%;
        font-size: var(--font-size-smallest-super);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 0px;    
    }

    .section-content .resume-item .resume-item-icon {        
        display: inline-block;
        width: 30px;
        float: right;
        -webkit-transition: all .3s linear;
        -o-transition: all .3s linear;
        transition: all .3s linear;
        color: #546ae0;
        padding: 0px 8px;
        margin: 0px 0px;
        margin-top: 14px;
    }

    .section-content .resume-item .resume-item-icon i {
        font-size: 24px;
    }

.resume-widget .resume-item {
    width: 110px;
    padding: 5px 10px;
    height: 60px;
}
    .resume-widget .resume-item .resume-item-content {
        width: 58px;
    }
        .resume-widget .resume-item .resume-item-content h4{
            font-size: var(--font-size-smallest);
        }
        .resume-widget .resume-item .resume-item-content p {
            white-space: pre-wrap;
            max-height: 23px;
            padding-top: 5px;
            font-size: var(--font-size-smallest-super-ultra);
        }
    .resume-widget .resume-item .resume-item-icon {

    }
        .resume-widget .resume-item .resume-item-icon img {
            width: 25px;
            padding-top: 3px;
        }
        .resume-widget .resume-item .resume-item-icon i {
            font-size: 18px;
        }

.resume-widget .resume-item.resume-item-small {
    width: 65px;
}
    .resume-widget .resume-item.resume-item-small .resume-item-content {
        text-align: center;
        width: 48px;
    }
    .resume-widget .resume-item.resume-item-small .resume-item-content h4 {
        font-size: var(--font-size-normal);
        font-weight: bold;
    }
.resume-widget .resume-item.resume-item-medium {
    width: 147px;
}
    .resume-widget .resume-item.resume-item-medium .resume-item-content {
        width: 90px;
    }
    .resume-widget .resume-item.resume-item-medium .resume-item-icon {
        margin-right: 5px;
    }
.resume-widget .resume-item.resume-item-odd {
    background: #212436;
    box-shadow: 0 2px 8px 0 rgba(0,0,0,0.40);
}
.resume-widget .resume-item.resume-item-large {
    width: 100%;
    max-width: 305px;
}
    .resume-widget .resume-item.resume-item-large .resume-item-content {
        width: 225px;
        padding: 15px 0px;
    }
        .resume-widget .resume-item.resume-item-large .resume-item-content h4 {
            display: inline-block;
            font-size: var(--font-size-large);
            margin-top: 0px;
            padding: 0px 24px;
        }
        .resume-widget .resume-item.resume-item-large .resume-item-content p {
            padding: 0px 10px;
            padding-top: 2px;
            width: 70px;
            float: left;
            font-size: var(--font-size-smallest-super);            
            color: #fff;
        }
    .resume-widget .resume-item.resume-item-large .resume-item-icon {
        float: left;
        width: 55px;
    }
        .resume-widget .resume-item.resume-item-large .resume-item-icon img {
            padding-top: 0px;
        }
.resume-item-last {
    margin-bottom: 0px !important;
}

.resume-graph {
    display: inline-block;
    float: left;    
    overflow: hidden;    
    background: #292C42;
    box-shadow: 0 2px 8px 0 rgba(0,0,0,0.40);
    margin: 10px 5px;
    margin-top: 0px;
    width: 100%;
    padding: 5px 10px;
    height: 130px;
}
.resume-graph.resume-graph-odd {
    background: #212436;
    box-shadow: 0 2px 8px 0 rgba(0,0,0,0.40);
}

/* FANCY BOX */
.fancybox-content {
    background: transparent !important;
    padding: 15px 15px;
    padding-top: 11px;
}

/* MAPBOX */
.mapboxgl-popup-content {
    font-size: var(--font-size-small);
    color: #000;
}
.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
    margin-bottom: 10px;
}
a.mapboxgl-ctrl-logo {
    display: none;
}

.infoBox img[src="http://www.google.com/intl/en_us/mapfiles/close.gif"] {
    display: none;
}

.marker_info .info-general .table>tbody>tr>td,
.marker_info .info-general .table>tbody>tr>th,
.marker_info .info-general .table>tfoot>tr>td,
.marker_info .info-general .table>tfoot>tr>th,
.marker_info .info-general .table>thead>tr>td,
.marker_info .info-general .table>thead>tr>th {
    border-top: 1px solid rgba(33, 86, 38, 0.28);
}

.marker_info .info {
    max-width: 300px;
    width: 100%;
    min-width: 290px;
    max-height: 320px;
    border-radius: 5px;
    background: #2c6e2f;
    color: #fff;
    position: relative;
    z-index: 9999999;
}

.marker_info .info img {
    display: block;
    border-radius: 100px;
    width: 110px;
    height: 110px;
    position: absolute;
    top: -53px;
    left: 90px;
    border: 7px solid #e9e5db;
    background: #ffffff;
    display: none;
}
.marker_info .info h2 {
    text-align: center;
    padding-top: 20px;
    font-family: Roboto,sans-serif;
    font-weight: 400;
    font-size: 12px;
    color: #ffffff;
}
.marker_info .info h2 span {
    width: 70px;
    height: 1px;
    background: #E73931;
    display: block;
    margin: 15px auto;
}
.marker_info .info span {
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    color: #FFFFFF;
    line-height: 22px;
    text-align: center;
    display: block;
    padding: 0px 30px;
    font-size: 14px;
}
.marker_info .info span.info-close {
  position: absolute;
  top: 5px;
  right: -15px;
}

.marker_info .info .info-general {
    display: block;
    padding: 0 30px;
    font-size: 10px;
    color: #fff;
}
.marker_info .info .info-general b {
  font-weight: bold;
  color: #fff;
}

.marker_info .info a {}
a.green_btn {
    background: #605ca8;
    border: 1px solid #605ca8;
    padding: 10px 50px;
    color: #ffffff;
    font-size: 14px;
    border-radius: 4px;
    display: block;
    width: 180px;
    text-align: center;
    bottom: 40px;
    position: absolute;
    left: 60px;
    transition: 0.3s;
}
a.green_btn:hover {
    background: #ffffff; /* Old browsers */
    border: 1px solid #000000;
    padding: 10px 50px;
    color: #E73931;
    font-size: 14px;
    box-shadow: none;
    border-radius: 4px;
    display: block;
    width: 180px;
    text-align: center;
    bottom: 40px;
    position: absolute;
    left: 60px;
    text-decoration: none;
}
.marker_info .arrow {
    width: 0px;
    height: 0px;
    position: absolute;
    padding: 0px !important;
    left: -28px;
    top: 150px !important;
    border: 14px solid rgba(255, 0, 0, 0);
    top: 0px;
    border-right-color: #2c6e2f;
}

.marker_info_close {
    position: absolute;
    top: 5px;
    right: 10px;
    color: #215626;
}

/* MAPS CONTROL */

.maps-container a[href^="http://maps.google.com/maps"]{display:none !important}
.maps-container a[href^="https://maps.google.com/maps"]{display:none !important}
.gm-fullscreen-control {
    background: none rgba(9, 38, 9, 0.29) !important;
    height: 25px !important;
    width: 25px !important;
}
    .gm-fullscreen-control img {
        height: 10px !important;
        width: 10px !important;
    }

#main-container {
  padding: 0px;
  margin: 0px;
}
.maps-container {  
    border-radius: 2px;
    width: 100%;
    height: 350px;
    overflow: hidden;
    margin: 0 0;
    margin-bottom: 15px;
    border-radius: 5px;
    border: 1px solid #215626;
    box-shadow: 0 6px 12px 0 #062709;
}
  .maps-container.maps-container-no-padding {
    box-shadow: none;
    border: none;
    background: none;
    padding: 0px 0px;
  }

.maps-container .mymap {
  width: 100%;  
  height: 100%;
}

#mymap-control {
    z-index: 9999998 !important;    
    font-family: Arial, sans-serif;
    min-width: 250px;
    max-width: 550px;
    width: 100%;
    max-height: 100%;
    height: 100%;
    overflow:auto;
}  
    #mymap-control.mymap-control-hide {
        height: 33px;
        bottom: 15px !important;
    } 
    #mymap-control .control-content {
        background-color: #fff;
        float: left;
        color: #000;
        width: 100%;        
        max-height: 100%;
        height: 100%;
        opacity: 0.9;  
        overflow:auto;        
        position: absolute;
        bottom: 0px;
        left: 0px;
        padding: 0px 15px;
        padding-top: 40px;
    }
        #mymap-control.mymap-control-hide .control-content {
            height: 0px;
            padding-top: 0px;
        }
    #mymap-control #mymap-control-toggle {
        display: inline-block;
        position: absolute;
        top: 0;
        right: 5px;
        padding: 10px 10px;
        background: #215626;
        color: #fff;
        border-radius: 0;
        font-size: 10px;
        margin-top: 2px;
        z-index: 9999999;
    }
        #mymap-control.mymap-control-hide #mymap-control-toggle {
            bottom: 0px;
            top: unset;
        }












@media only screen and (min-width: 2560px)  { 
    
}
